<template>
  <v-row>
    <v-col v-if="print" cols="12" md="12" class="my-font-class">
      <VueHtml2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="invoice"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <base-card id="invoice">
            <div class="mb-10">
              <div
                style="
                  width: 80%;
                  margin-right: auto !important;
                  margin-left: auto !important;
                "
              >
                <v-row>
                  <v-col cols="12">
                    <div class="">
                      <div
                        class=""
                        style="
                          background: #f5f5f5;
                          text-align: center;
                          padding: 16px 0;
                          margin-top: 30px;
                          width: 100%;
                        "
                      >
                        <img
                          class="mr-2"
                          src="@/assets/logo.png"
                          alt=""
                          srcset=""
                          style="width: 150px"
                        />
                      </div>
                      <v-divider dark class="my-12"></v-divider>
                    </div>

                    <div
                      class=""
                      style="padding-bottom: 20px !important; width:100%; margin-left: 13px;}"
                    >
                      <tr>
                        <td style="width: 75%">
                          <h5>{{ company.name }}</h5>
                          <p>
                            <strong>Address:</strong> {{ company.address }}
                            <br />
                            <strong>Attendant:</strong> {{ loggedInUser }}
                          </p>
                        </td>
                      </tr>
                    </div>

                    <div
                      class=""
                      style="padding-top: 20px !important; padding-bottom: 20px !important; width:100%; margin-left: 13px;}"
                    >
                      <tr>
                        <td style="width: 75%">
                          <h6 style="font-weight: 600">#{{ invoiceId }}</h6>
                          <h6 style="font-weight: 600">Item purchased on:</h6>
                        </td>
                        <td style="width: 20% !important">
                          <h6 style="text-align: right !important">
                            {{ new Date().toLocaleString() }}
                          </h6>
                        </td>
                      </tr>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
            <v-card-text style="letter-spacing: 0.0071428571em">
              <div
                style="
                  width: 80%;
                  margin-right: auto !important;
                  margin-left: auto !important;
                "
              >
                <div
                  style="
                    background-color: #ffffff;
                    color: rgba(0, 0, 0, 0.87);
                    line-height: 1.5;
                    max-width: 100%;
                    border-radius: 4px;
                    overflow-x: auto;
                    overflow-y: hidden;
                  "
                >
                  <table
                    style="width: 100%; border-spacing: 0; line-height: 1.5"
                  >
                    <thead>
                      <tr style="border-bottom: thin solid rgba(0, 0, 0, 0.12)">
                        <th
                          style="
                            font-size: 0.875rem;
                            color: rgba(0, 0, 0, 0.6);
                            height: 48px;
                            padding: 0 16px;
                            text-align: left !important;
                            padding-left: 0px !important;
                            user-select: none;
                            border-bottom: thin solid rgba(0, 0, 0, 0.12);
                            transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          "
                        >
                          Items
                        </th>
                        <th
                          style="
                            font-size: 0.875rem;
                            border-bottom: thin solid rgba(0, 0, 0, 0.12);
                            color: rgba(0, 0, 0, 0.6);
                            height: 48px;
                            user-select: none;
                            padding: 0 16px;
                            transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                            text-align: right !important;
                            padding-right: 0px !important;
                          "
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="hover:bg-transparent"
                        v-for="item in product"
                        :key="item.id"
                      >
                        <td
                          style="
                            font-size: 1rem;
                            font-weight: 700;
                            border-bottom: 2px solid rgba(0, 0, 0, 0.12);
                            height: 48px;
                            padding: 0 16px;
                            transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                            padding-left: 0px !important;
                          "
                        >
                          {{ item.quantity }} of
                          {{ item.product }}
                        </td>
                        <td
                          style="
                            font-size: 1rem;
                            font-weight: 700;
                            border-bottom: 2px solid rgba(0, 0, 0, 0.12);
                            height: 48px;
                            padding: 0 16px;
                            transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                            text-align: right !important;
                            padding-right: 0px !important;
                          "
                        >
                          {{ currency }}{{ item.price * item.quantity }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <table style="width: 100%; border-spacing: 0; line-height: 1.5">
                  <thead>
                    <tr style="border-bottom: thin solid rgba(0, 0, 0, 0.12)">
                      <th
                        style="
                          font-size: 0.875rem;
                          color: rgba(0, 0, 0, 0.6);
                          height: 48px;
                          padding: 0 16px;
                          text-align: left !important;
                          padding-left: 0px !important;
                          user-select: none;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                        "
                      ></th>
                      <th
                        style="
                          font-size: 0.875rem;
                          color: rgba(0, 0, 0, 0.6);
                          height: 48px;
                          user-select: none;
                          padding: 0 16px;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          text-align: right !important;
                          padding-right: 0px !important;
                        "
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="hover:bg-transparent">
                      <td
                        style="
                          font-size: 1rem;
                          font-weight: 600;
                          padding: 0 16px;
                          text-align: right !important;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          padding-left: 0px !important;
                        "
                      >
                        Calculated Total
                      </td>
                      <td
                        style="
                          font-size: 1rem;
                          font-weight: 600;
                          padding: 0 16px;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          text-align: right !important;
                          padding-right: 0px !important;
                        "
                      >
                        {{ currency }}{{ totalPrice }}
                      </td>
                    </tr>
                    <tr class="hover:bg-transparent">
                      <td
                        style="
                          font-size: 1rem;
                          font-weight: 600;
                          padding: 0 16px;
                          text-align: right !important;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          padding-left: 0px !important;
                        "
                      >
                        Discount
                      </td>
                      <td
                        style="
                          font-size: 1rem;
                          font-weight: 600;
                          padding: 0 16px;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          text-align: right !important;
                          padding-right: 0px !important;
                        "
                      >
                        {{ discount }}%
                      </td>
                    </tr>
                    <tr class="hover:bg-transparent">
                      <td
                        style="
                          font-size: 1rem;
                          font-weight: 600;
                          padding: 0 16px;
                          text-align: right !important;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          padding-left: 0px !important;
                        "
                      >
                        Tax
                      </td>
                      <td
                        style="
                          font-size: 1rem;
                          font-weight: 600;
                          padding: 0 16px;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          text-align: right !important;
                          padding-right: 0px !important;
                        "
                      >
                        {{ tax }}
                      </td>
                    </tr>
                    <tr class="hover:bg-transparent">
                      <td
                        style="
                          font-size: 1rem;
                          font-weight: 700;
                          padding: 0 16px;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          padding-left: 0px !important;
                        "
                      ></td>
                      <td
                        style="
                          font-size: 1.5rem;
                          font-weight: 900;
                          padding: 0 16px;
                          transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
                          text-align: right !important;
                          padding-right: 0px !important;
                        "
                      >
                        {{ currency }}{{ grandTotal }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-card-text>

            <div style="padding-top: 4rem; margin-bottom: 1.5rem; width: 100%">
              <div style="text-align: center">
                <button
                  style="
                    background-color: #3869d4;
                    border-top: 10px solid #3869d4;
                    border-right: 18px solid #3869d4;
                    border-bottom: 10px solid #3869d4;
                    border-left: 18px solid #3869d4;
                    display: inline-block;
                    margin-top: 10px;
                    margin-bottom: 15px;
                    color: #fff;
                    text-decoration: none;
                    border-radius: 3px;
                    box-sizing: border-box;
                  "
                >
                  Paid with: {{ paymentType }}
                </button>
              </div>
            </div>
          </base-card>
        </section>
      </VueHtml2pdf>
      <div class="bg-off-white py-4">
        <div class="mx-auto w-4/5">
          <div class="flex justify-between flex-wrap">
            <v-btn
              class="m-2"
              @click="newSale"
              style="float: right"
              color="primary"
            >
              New Sale
            </v-btn>
            <v-btn color="primary" outlined @click="sendMailDialog">Send</v-btn>

            <v-btn @click="printInvoice" color="primary" class="text-white"
              >Print Invoice</v-btn
            >
          </div>
        </div>
      </div>
      <!-- <v-btn
        class="m-2"
        @click="printInvoice"
        style="float: right"
        color="primary"
      >
        Print
      </v-btn>
      <v-btn
        class="m-2"
        @click="sendMailDialog"
        style="float: right"
        color="primary"
        :loading="loading"
        :disabled="loading"
      >
        Send
      </v-btn> -->
    </v-col>
    <div v-if="print" class="text-center">
      <v-dialog v-model="sendDialog" width="500">
        <base-card>
          <v-card-title>Send Invoice</v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <v-list flat>
                <v-text-field
                  filled
                  color="info"
                  v-model="email"
                  :rules="emailRules"
                  type="email"
                  placeholder="Enter Email"
                ></v-text-field>
              </v-list>
            </v-card-text>
            <v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="mt-n10"
                  color="primary"
                  @click="sendInvoice"
                  :loading="loadingE"
                  :disabled="loadingE"
                  >Send Invoice</v-btn
                >
              </v-card-actions>
            </v-container>
          </v-form>
        </base-card>
      </v-dialog>
    </div>
    <v-card-title v-if="!print">
      <div class="flex justify-between flex-wrap">
        <v-btn
          v-if="viewSales"
          class="mb-1 mt-1 button"
          dark
          color="primary"
          @click="$router.push('/app/sales/view')"
        >
          <v-icon>mdi-eye</v-icon>
          View Sales
        </v-btn>
        <div></div>
      </div>
    </v-card-title>
    <v-col v-if="!print" cols="12" md="12">
      <v-row>
        <v-col v-if="showStoreSelect" class="d-flex" cols="6" sm="6">
          <v-select
            v-model="storeId"
            :disabled="isStore"
            :items="items"
            label="Select Store"
            style="min-height: 41px !important"
            item-value="id"
            item-text="name"
            solo
          ></v-select>
        </v-col>
        <v-col cols="6">
          <!-- <v-toolbar flat class="pl-0"> -->
          <v-text-field
            clearable
            class="mx-0 pl-0 elevation-0"
            hide-no-data
            hide-details
            filled
            dense
            label="Search..."
            v-model="searchQuery"
            solo
          ></v-text-field>

          <!-- </v-toolbar> -->
          <v-list-item
            style="background: #fff"
            class="mt-1"
            v-for="item in resultQuery"
            :key="item.id"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.product.name"></v-list-item-title>
              <v-list-item-subtitle
                v-text="item.product.price"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn v-if="added" disabled class="mr-2" small color="muted">
                <v-icon left>mdi-check</v-icon> Added
              </v-btn>
              <v-btn
                v-if="!added"
                @click="addStock(item)"
                class="mr-2"
                small
                color="info"
              >
                <v-icon left>mdi-plus</v-icon> Add
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="12">
      <v-row v-if="!print">
        <v-col cols="12" md="8">
          <div v-if="addProduct">
            <base-card class="mb-3" v-for="item in product" :key="item.id">
              <v-card-text>
                <div class="flex align-center justify-between flex-wrap">
                  <div class="flex flex-wrap mb-4">
                    <div>
                      <h5 class="font-thin">{{ item.product }}</h5>

                      <p class="mb-2 text-lg">{{ currency }}{{ item.price }}</p>

                      <div>
                        <v-btn
                          @click="removeStock(item)"
                          class="mr-2"
                          small
                          color="info"
                        >
                          <v-icon left>mdi-close</v-icon> Remove
                        </v-btn>
                      </div>
                    </div>
                  </div>

                  <div>
                    <v-btn-toggle mandatory tile class="flex align-center">
                      <v-btn
                        @click="decrementItem(item)"
                        small
                        depressed
                        color=""
                      >
                        <v-icon color="">mdi-minus</v-icon>
                      </v-btn>
                      <span class="align-middle mx-2">
                        <!-- <v-text-field class="input" :value="itema.quantity" @keyup="changeQuantity(itema)" style="width: 300px; text-align:center"></v-text-field> -->
                        {{ item.quantity }}
                      </span>
                      <v-btn
                        @click="incrementItem(item)"
                        small
                        depressed
                        color=""
                      >
                        <v-icon color="">mdi-plus</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                    <v-chip
                      class="mt-4 ml-3"
                      color="success"
                      small
                      text-color="white"
                    >
                      {{ item.available }} Available
                    </v-chip>
                  </div>
                </div>
              </v-card-text>
            </base-card>
          </div>
          <!--<base-card class="mb-3" >
            <v-card-text>
              <div class="flex align-center justify-between flex-wrap">
                <div class="flex flex-wrap mb-4">
                  <div >
                    <h5 class="font-thin">{{item.product.name}}</h5>-->
          <!-- <v-chip
                          class="mb-4"
                          color="success"
                          small
                          text-color="white"
                        >
                          In Stock
                        </v-chip> -->
          <!--<p class="mb-2 text-lg">₦{{item.product.price}}</p>

                    <div>
                      <v-btn @click="addStock(item)" class="mr-2" small color="info">
                        <v-icon left>mdi-close</v-icon> Add
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
            </base-card>-->
        </v-col>
        <v-col cols="12" md="4">
          <base-card class="mb-3">
            <v-card-title>Options</v-card-title>
            <v-card-text>
              <v-form ref="form" v-model="valid" lazy-validation>
                <div class="flex justify-between mb-3">
                  <v-select
                    v-model="paymentType"
                    :items="paymentTypes"
                    label="Payment Type"
                    :rules="emptyRules"
                  ></v-select>
                </div>
                <div class="flex justify-between mb-3">
                  <v-select
                    v-model="paymentStatus"
                    :items="paymentStatues"
                    label="Payment Status"
                    :rules="emptyRules"
                  ></v-select>
                </div>
              </v-form>
              <v-divider></v-divider>
              <div class="mt-3">
                <h6 class="text--disabled">Price Details</h6>

                <div class="flex justify-between">
                  <p class="text--disabled mb-1 text-lg mr-2">Total :</p>
                  <p class="text--disabled mb-1 text-lg">
                    {{ currency }}{{ totalPrice }}
                  </p>
                </div>
                <div class="flex justify-between mt-2">
                  <p class="text--disabled mb-1 text-lg mr-2">Tax:</p>
                  <p class="text-success mb-1 text-lg">
                    <v-text-field
                      v-model="tax"
                      style="width: 35px; margin-top: -19px"
                      color="primary"
                    ></v-text-field>
                  </p>
                </div>
                <div class="flex justify-between mt-2">
                  <p class="text--disabled mb-1 text-lg mr-2">Discount Tax:</p>
                  <p class="text-success mb-1 text-lg">
                    <v-text-field
                      v-model="discount"
                      style="width: 35px; margin-top: -19px"
                      color="primary"
                    ></v-text-field>
                  </p>
                  <p
                    style="margin-left: -102px"
                    class="text--disabled mb-1 text-lg"
                  >
                    %
                  </p>
                </div>

                <v-divider class="my-3"></v-divider>

                <div class="flex justify-between">
                  <h6 class="font-bold mb-1 text-lg mr-2">Grand Total:</h6>
                  <h6 class="mb-1 text-lg">{{ currency }}{{ grandTotal }}</h6>
                </div>
              </div>
            </v-card-text>
          </base-card>
        </v-col>
      </v-row>

      <v-btn
        v-if="!print"
        class="m-2"
        @click="recordSales"
        style="float: right"
        color="primary"
        :loading="loading"
        :disabled="loading"
      >
        Record Sales
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
//local registration
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { handleError } from "../../../constants/error";
import store from "../../../store/index";
import Swal from "sweetalert2";
import VueHtml2pdf from "vue-html2pdf";
import {
  COMPANY_STORE,
  CREATE_NEW_SALES,
  GET_STORE_STOCK_LIST,
  GET_DASHBOARD_DATAS,
  SEND_MAIL,
} from "../../../constants/graphql";

export default {
  //component code
  components: {
    FormWizard,
    TabContent,
    VueHtml2pdf,
  },
  data() {
    return {
      loading: false,
      loadingE: false,
      userRole: store.state.authData.role || "",
      company: store.state.authData.company,
      loggedInUser: store.state.authData.userName,
      currency: store.state.authData.currency,
      items: [],
      email: null,
      searchQuery: null,
      storeId: this.$store.state.authData.userStore.id || "",
      userPermission: this.$store.state.authData.userPermissions,
      stocks: [],
      sales: [],
      tax: 0,
      resources: [],
      resource: "",
      product: [],
      print: false,
      paymentType: "",
      paymentStatus: "",
      paymentTypes: ["Cash", "POS", "Transfer"],
      paymentStatues: ["Paid", "Pending"],
      quantity: 0,
      discount: 0,
      added: false,
      emptyRules: [(v) => !!v || "Field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      valid: true,
      invoiceId: "",
      sendDialog: false,
    };
  },

  apollo: {
    items: {
      query: COMPANY_STORE,
      update: (result) => result.getCompanyStoreList,
      error(err) {
        handleError(err);
      },
    },
    resource: {
      query: GET_STORE_STOCK_LIST,
      variables() {
        return {
          storeId: this.storeId,
        };
      },
      update: (result) => result.getStoreStockList,
      error(error) {
        handleError(error);
      },
    },
  },
  computed: {
    resultQuery() {
      this.getStock();
      if (this.searchQuery) {
        let out = [...this.resource];
        return out.filter((items) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => items.product.name.toLowerCase().includes(v));
        });
      }
    },
    viewSales() {
      if (this.userPermission.includes("view_sales")) {
        return true;
      }
    },
    isStore() {
      return false;
    },
    addProduct() {
      if (this.product !== "") {
        return true;
      }
    },
    showStoreSelect() {
      return false;
    },
    totalPrice() {
      if (this.product.length > 0) {
        let total = this.product
          .map((item) => item.price * item.quantity)
          .reduce((prev, next) => prev + next);
        return total;
      } else {
        return 0;
      }
    },
    grandTotal() {
      return this.totalPrice - (this.totalPrice * this.discount) / 100;
    },
  },
  methods: {
    getStock() {
      this.$apollo
        .query({
          query: GET_STORE_STOCK_LIST,
          variables: {
            storeId: this.storeId,
          },
        })
        .then((data) => {
          this.resources = data.data.getStoreStockList;
        });
    },
    newSale() {
      this.$apollo.queries.resource.refetch();
      this.print = false;
      this.product = [];
      this.sales = [];
      this.discount = 0;
      this.paymentType = "";
      this.paymentStatus = "";
      this.tax = 0;
    },
    addStock(item) {
      var a = this.product.findIndex((obj) => obj.product == item.product.name);
      if (this.product[a]) {
        this.$swal.fire({
          toast: true,
          text: `Already Exist`,
          icon: "success",
          timer: 3000,
          timerProgressBar: true,
          position: "top-end",
        });
      } else {
        this.product.push({
          product: item.product.name,
          stock_id: item.id,
          quantity: 1,
          price: item.product.price,
          available: item.quantity,
        });
        this.sales.push({ stock_id: item.id, quantity: 1 });
        this.searchQuery = "";
      }
    },
    removeStock(item) {
      this.product.splice(this.product.indexOf(item), 1);
      this.sales.splice(this.sales.indexOf(item), 1);
    },
    incrementItem(item) {
      var a = this.product.findIndex((obj) => obj.stock_id == item.stock_id);
      var b = this.resources.findIndex((obj) => obj.id == item.stock_id);
      var c = this.sales.findIndex((obj) => obj.stock_id == item.stock_id);
      if (item.quantity == this.resources[b].quantity) {
        this.$swal.fire({
          toast: true,
          text: `Maximum Exceeded`,
          icon: "error",
          timer: 3000,
          timerProgressBar: true,
          position: "top-end",
        });
      } else {
        var quantity = (item.quantity += 1);
        this.product[a].quantity = quantity;
        this.sales[c].quantity = quantity;
      }
    },
    decrementItem(item) {
      this.quantity = parseInt(this.quantity);
      var a = this.product.findIndex((obj) => obj.stock_id == item.stock_id);
      var b = this.resources.findIndex((obj) => obj.id == item.stock_id);
      var c = this.sales.findIndex((obj) => obj.stock_id == item.stock_id);
      if (item.quantity <= 1) {
        this.$swal.fire({
          toast: true,
          text: `Minimum Exceeded`,
          icon: "error",
          timer: 3000,
          timerProgressBar: true,
          position: "top-end",
        });
      } else {
        const quantity = (item.quantity -= 1);
        this.product[a].quantity = quantity;
        this.sales[c].quantity = quantity;
      }
    },
    recordSales() {
      let validated = this.$refs.form.validate();
      if (validated === true) {
        this.loading = true;
        this.$apollo
          .mutate({
            mutation: CREATE_NEW_SALES,
            variables: {
              stocks: JSON.stringify(this.sales),
              paymentType: this.paymentType,
              paymentStatus: this.paymentStatus,
              discount: parseFloat(this.discount),
              totalPrice: parseFloat(this.grandTotal),
              tax: parseFloat(this.tax),
            },
          })
          .then((data) => {
            this.$swal.fire({
              toast: true,
              text: `Sales has been created successfully`,
              icon: "success",
              timer: 3000,
              timerProgressBar: true,
              position: "top-end",
            });
            this.print = true;
            this.invoiceId = data.data.createNewSales.invoiceId;
          });
        this.loading = false;
      }
    },
    async printInvoice() {
      await this.$htmlToPaper("invoice");
    },
    sendMailDialog() {
      this.sendDialog = true;
    },
    sendInvoice() {
      this.loadingE = true;
      let validated = this.$refs.form.validate();
      if (validated) {
        this.$apollo
          .mutate({
            mutation: SEND_MAIL,
            variables: {
              recipientEmail: this.email,
              salesId: this.salesId,
            },
          })
          .then((res) => {
            this.loadingE = false;
            this.sendDialog = false;
            this.$swal.fire({
              toast: true,
              text: `Invoice has been sent to ${this.email} successfully`,
              icon: "success",
              timer: 5000,
              timerProgressBar: true,
              position: "top-end",
            });
          });
      }
    },
  },
};
</script>

<style>
/*.v-text-field input {
  text-align: center !important;
}*/
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 41px !important;
  padding: 0;
}
.my-font-class {
  font-family: "Century Gothic", monospace !important;
}
tr {
  color: #000 !important;
}

td {
  color: #000 !important;
}

th {
  color: #000 !important;
}
p {
  color: #000 !important;
}
</style>
